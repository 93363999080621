import React, { useContext, useEffect, useState } from 'react';
import Styles, { Colors } from 'Styles';
import {
  PAGE_HEIGHT,
  PAGE_WIDTH,
  PALLET_PRODUCT_FIELDS,
  UserContext,
} from '../utilities/DataTypes';
import { API_URL } from '../env';
import Log from './Logger';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faSave } from '@fortawesome/free-solid-svg-icons';
import { formatter } from '../utilities/Helpers';
import { TextField, CircularProgress } from '@mui/material';

// Styles

// Smart components

type PalletProduct = {
  pallet_product_id: number;
  pallet_sku: string;
  pallet_product_title: string;
  pallet_product_retail_price: number;
  pallet_product_retail_percentage: number;
  pallet_product_cost_per_unit: number;
  pallet_product_quantity: number;
};


const getProducts = async (
  palletSku: string,
  auth: string
): Promise<PalletProduct[]> => {
  return await fetch(API_URL + 'getPalletProducts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify({
      pallet_sku: palletSku,
    }),
  })
    .then((response) => response.json())
    .then((response: { data: PalletProduct[] }) => {
      return response.data;
    })
    .catch((err) => {
      Log(2, err);
      throw err;
    });
};

const deletePalletProduct = (
  palletProductId: number,
  auth: string
): Promise<boolean> => {
  return fetch(API_URL + 'deletePalletProduct', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify({
      pallet_product_id: palletProductId,
    }),
  })
    .then((response) => {
      return response.ok;
    })
    .catch((err) => {
      Log(2, err);
      throw err;
    });
};

// Dumb components

interface ProductsTableProps {
  palletSku: string;
  results: any[];
  loading: boolean;
  view: boolean;
  setRefresh: any;
}

const ProductsTable = (props: ProductsTableProps) => {
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [results, setResults]: [any, any] = useState<any[]>([]);

  const handleSaveClick = (palletProductInfo:any, auth:string)=>{
    //console.log(palletProductInfo);
    updatePalletProduct(palletProductInfo, auth)
    .then((success) => {
      if (success) {
        enqueueSnackbar(
          `Updated product ${palletProductInfo[
            'pallet_product_title'
          ].substring(0, 25)}`,
          { variant: 'success' }
        );
        props.setRefresh();
      } else {
        enqueueSnackbar(
          `Failed to update product ${palletProductInfo[
            'pallet_product_title'
          ].substring(0, 25)}`,
          { variant: 'error' }
        );
      }
    })
    .catch((_) => {
      enqueueSnackbar(
        `Failed to update product ${palletProductInfo[
          'pallet_product_title'
        ].substring(0, 25)}`,
        { variant: 'error' }
      );
    });
  }
  
  const updatePalletProduct = (
    palletProductInfo: any,
    auth: string
  ): Promise<boolean> => {
    //console.log('reached updatepalletproduct');
    return fetch(API_URL + 'updatePalletProduct', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: auth,
      },
      body: JSON.stringify({
        fields: {
          pallet_product_id: palletProductInfo.pallet_product_id,
          pallet_sku: palletProductInfo.pallet_sku,
          pallet_product_title: palletProductInfo.pallet_product_title,
          pallet_product_retail_price: palletProductInfo.pallet_product_retail_price,
          pallet_product_retail_percentage: palletProductInfo.pallet_product_retail_percentage,
          pallet_product_cost_per_unit: palletProductInfo.pallet_product_cost_per_unit,
          pallet_product_quantity: palletProductInfo.pallet_product_quantity
        },
      }),
    })
      .then((response) => {
        return response.status.toString().substring(0, 1) === '2';
      })
      .catch((err) => {
        Log(2, err);
        return false;
      });
  };
  useEffect(() => {
    setResults(props.results);
  }, [props.results]);

  if (results !== undefined && results.length > 0) {
    return (
      <table
        style={{
          borderCollapse: 'collapse',
          tableLayout: 'fixed',
          boxSizing: 'border-box',
          width: '100%',
        }}
      >
        <thead>
          <tr>
            {Object.keys(PALLET_PRODUCT_FIELDS).map(
              (field: any, index: number) => {
                return (
                  <Styles.StyledTH
                    key={index}
                    style={{
                      width: field === 'TITLE' ? '20vw' : '10vw',
                    }}
                  >
                    {field}
                  </Styles.StyledTH>
                );
              }
            )}

            {!props.view && (
              <Styles.StyledTH
                style={{
                  width: '10vw',
                }}
              >
                ACTIONS
              </Styles.StyledTH>
            )}
          </tr>
        </thead>
        <tbody>
          


          {results.map((result: any, top_index: number) => (
            <tr
              key={'TR-' + result['pallet_product_id']}
              style={{ borderBottom: 'thin solid black' }}
            >
              {Object.values(PALLET_PRODUCT_FIELDS).map(
                (field: any, index: number) => {
                  switch (field) {
                    case PALLET_PRODUCT_FIELDS['RETAIL PRICE']:
                    case PALLET_PRODUCT_FIELDS['COST PER UNIT']:
                      return (
                        <Styles.StyledTD key={index}>
                          {result[field]
                            ? formatter.format(result[field])
                            : 'NONE'}
                        </Styles.StyledTD>
                      );
                    case PALLET_PRODUCT_FIELDS['% OF RETAIL']:
                      return (
                        <Styles.StyledTD key={index}>
                          {result[field]
                            ? result[field].toFixed(2).toLocaleString() + '%'
                            : 'NONE'}
                        </Styles.StyledTD>
                      );
                      case PALLET_PRODUCT_FIELDS['QUANTITY']:
                        return (
                          <Styles.StyledTD key={index}>
                            <TextField
                              type="number"
                              InputProps={{
                                inputProps: { 
                                    min: 1 
                                }
                            }}
                              value = {result['pallet_product_quantity'] ?? 0}
                              onChange={(e)=>{
                                let temp_results = [...results];
                                result.pallet_product_quantity = e.target.value;
                                temp_results[top_index] = result;
                                console.log({temp_results})
                                setResults(temp_results);
                              }}
                            />
                          </Styles.StyledTD>
                        );
                    default:
                      return (
                        <Styles.StyledTD key={index}>
                          {result[field] || 'NONE'}
                        </Styles.StyledTD>
                      );
                  }
                }
              )}
              {!props.view && (
                <Styles.StyledTD>
                  <Styles.TableButton
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                    }}
                    onClick={() => {
                      deletePalletProduct(
                        result['pallet_product_id'],
                        userContext.auth
                      )
                        .then((success) => {
                          if (success) {
                            enqueueSnackbar(
                              `Removed product ${result[
                                'pallet_product_title'
                              ].substring(0, 25)}`,
                              { variant: 'success' }
                            );
                            setResults((oldResults: any) =>
                              oldResults.filter(
                                (r: PalletProduct) =>
                                  r.pallet_product_id !==
                                  result['pallet_product_id']
                              )
                            );
                            props.setRefresh();
                          } else {
                            enqueueSnackbar(
                              `Failed to remove product ${result[
                                'pallet_product_title'
                              ].substring(0, 25)}`,
                              { variant: 'error' }
                            );
                          }
                        })
                        .catch((_) => {
                          enqueueSnackbar(
                            `Failed to remove product ${result[
                              'pallet_product_title'
                            ].substring(0, 25)}`,
                            { variant: 'error' }
                          );
                        });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faMinusSquare}
                      style={{ width: '24px', height: '24px', marginBottom: 0 }}
                      color={Colors.red}
                    />
                    <p style={{ fontSize: 12, marginTop: 0 }}>Remove</p>
                  </Styles.TableButton>
                  <Styles.TableButton
                      style={{padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,}}
                      onClick={()=>{
                        handleSaveClick(result, userContext.auth);
                      }}>
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{width: '24px', height: '24px', marginBottom: 0}}
                      color={Colors.green}
                      />
                    <p style={{ fontSize: 12, marginTop: 0 }}> Save </p>
                  </Styles.TableButton>
                </Styles.StyledTD>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return (
      <p>
        {props.loading ? (
          <CircularProgress sx={{ mx: 'auto' }} />
        ) : (
          'No Results'
        )}
      </p>
    );
  }
};

interface PalletProductsTableProps {
  palletSku: string;
  view: boolean;
  reloadProducts: boolean;
  setReloadProducts: any;
}

const PalletProductsTable = (props: PalletProductsTableProps) => {
  const userContext = useContext(UserContext);
  const [products, setProducts]: [any[], any] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoadingResults(true);
    getProducts(props.palletSku, userContext.auth)
      .then((response) => {
        setProducts(response);
        setLoadingResults(false);
      })
      .catch((_) => {
        enqueueSnackbar('Failed to load pallet products.', {
          variant: 'error',
        });
        setLoadingResults(false);
      });
    props.setReloadProducts(false);
  }, [userContext.auth, enqueueSnackbar, props]);

  return (
    <Styles.Page
      style={{
        flexGrow: 0,
        marginTop: 20,
        marginBottom: 10,
        minWidth: PAGE_WIDTH * 0.9,
        maxWidth: PAGE_WIDTH * 0.9,
        maxHeight: PAGE_HEIGHT * 0.5,
        overflow: 'scroll',
        alignItems: 'flex-start',
      }}
    >
      <div style={{ display: 'inline', flex: 1, minWidth: 1200 }}>
        <ProductsTable
          palletSku={props.palletSku}
          results={products}
          loading={loadingResults}
          view={props.view}
          setRefresh={() => props.setReloadProducts(!props.reloadProducts)}
        />
      </div>
    </Styles.Page>
  );
};

export default PalletProductsTable;
